import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My recent <strong className="purple">projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              // imgPath={leaf}
              isBlog={false}
              title="shex - Load Testing CLI"
              description="shex is developed using Go, It allows users to conduct load tests on their web applications or APIs to ensure they can handle high traffic. Using this Tool, user will specify the number of concurrent users (requests), duration and target url of the test. It will then generate a report with the results of the test including success and failure rate."
              link="https://github.com/shebogholo/shex"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              // imgPath={leaf}
              isBlog={false}
              title="Swahili News API"
              description="The Swahili News API is a project that crawls online news sources in the Swahili language and serves the collected news headlines as an API. This API allows developers to access a wide range of Swahili news content from various sources in a consistent and easy-to-use format for developers to consume on their applications."
              link="https://shebogholo.com/habari/api/v1/articles"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              // imgPath={leaf}
              isBlog={false}
              title="Project Africa"
              description="Project Africa is a Data Collection and Research Project that aims to create Natural Language Processing (NLP) Tools and Models for the Swahili Language. The goal of this project is to support and promote the use of Swahili Language in Technology and make it more accessible to the Speakers of Swahili Language both in Africa and the rest of the world."
              link="https://africa.shebogholo.com"
            />
          </Col>


        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
