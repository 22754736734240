import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
import { AiOutlineDownload } from "react-icons/ai";

function Resume() {
 

  return (
    <Container fluid className="resume-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", position: "relative" }}>

        </Row>
        <Row className="resume">
          <Col md={6} className="resume-left">
            <h3 className="resume-title">Experience</h3>
            <Resumecontent
              title="CTO, Belltro"
              date="09/2019 - 04/2022"
              content={[
                "Working on internal NLP projects for the company.",
                "Developed Machine Learning models to classify documents based on adverse drug reaction cases.",
                "Created internal Natural Language processing APIs for Kiswahili in Named Entity Recognition and Sentiment Analysis.",
              ]}
            />

            <Resumecontent
              title="Data Scientist, ParrotAI"
              date="08/2018 - 09/2019"
              content={[
                "Worked on image processing for machine learning and deep learning.",
                "Developed efficient deep learning model to identify affected banana leaves.",
                "Collaborate on preparing curriculum for capacity building programs of the company.",
              ]}
            />

            <Resumecontent
              title="Intern ML Researcher, SignAILab"
              date="02/2019 - 09/2020"
              content={[
                "Investigated how Artificial Intelligence can be used to combat climate change.",
                "Developed deep learning model to extract buildings from drone images.",
                "Contributed on preparing skills development plan for students.",
              ]}
            />


            {/* <h3 className="resume-title">Extracurricular Activities</h3>
            <Resumecontent
              title="Web Developer [Pantheon-2019 Technical Fest of BIT Mesra]"
              content={[
                "Worked on building front-end UI design using HTML5, CSS3, JavaScript jQuery, and building API routes using Node and express.js.",
              ]}
            /> */}
          </Col>
          <Col md={6} className="resume-right">
            <h3 className="resume-title">Education</h3>
            <Resumecontent
              title="BSc. Software Engineering"
              date="2016 - 2020"
              content={[]}
            />

            {/* <h3 className="resume-title">Publications</h3>
            <Resumecontent
              title=""
              content={[
                "Article entitled An Overlapping Sliding Window and Combined Feature based Emotion Recognition System for EEG Signals publised in Emerald Publication;10.1108/ACI-05-2021-0130",
              ]}
            />

            <h3 className="resume-title">Ranks and Achivements</h3>
            <Resumecontent
              title=""
              content={[
                `Current rank in Spoj ${spojRank}`,
                `Current rank in HackerRank  ${hackerrank}`,
                "Top Performer in Code-Break 1.0",
                "Participant in Hack-A-Bit 2019",
              ]}
            /> */}
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href="https://drive.google.com/file/d/1Z4Y0qhiuMYi0V_BWkMBZfzSjtMkzG2zz/view?usp=sharing" target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </Container>
  );
}

export default Resume;
