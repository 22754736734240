import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Let me <span className="purple"> introduce </span> myself
            </h1>
            <p className="home-about-body">
                I'm a <span className="purple"> Software Engineer </span> and I absolutely love <span className="purple">Data</span> and <span className="purple"> Programming</span>. 
                They're both so fascinating to me and I really enjoy building <span className="purple">Backend Systems</span> and applying <span className="purple">Machine Learning</span>. 
                It's always a challenge to find new ways to improve systems and make them more efficient,
                and I love the feeling of accomplishment when I finally figure it out.
                <br />
                <br />

                I also like working with other people and solving problems together. 
                Whether it's in a team or working with clients, the feeling of making something better together is amazing 😊
                <br />
                <br />

                Thanks for checking out my website! I hope you find it useful and informative about <span className="purple">Me</span>🙂
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Let's connect</h1>
            <p>
              I'll be happy to <span className="purple">connect </span>with you
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/shebo/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://github.com/shebogholo"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/shebogholo"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
