import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          I am a <strong className="purple">Software Engineer </strong> with experience in backend technologies and machine learning. 
          I have a strong foundation in programming languages such as <strong className="purple">Java, PHP, Python and Go </strong> and 
          I have used frameworks such as Spring Boot, Laravel and FastAPI to build scalable, high-performance web applications.
          <br />
          <br />


          I have also utilized databases such as <strong className="purple">Oracle, MariaDB, PostgreSQL, MongoDB and Cassandra </strong> to store 
          and manage data efficiently. Additionally, I have experience with <strong className="purple">Elasticsearch </strong> for searching 
          and analyzing large datasets.
          <br />
          <br />

          In some projects, I worked with <strong className="purple">Rasa</strong>, a powerful open-source machine learning framework for building 
          Conversational AI (Chatbots) and other <strong className="purple">Natural Language Processing</strong> applications.

          <br />
          <br />
          I am a proactive and detail-oriented problem-solver with excellent communication skills. 
          I am comfortable working in a fast-paced environment and thrive under pressure. I am constantly 
          seeking opportunities to learn and grow as a <strong className="purple"> Software Engineer</strong> and am always open to new challenges 
          and opportunities.
          </p>
         
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
